import styled from 'styled-components';

import theme from 'utils/theme';

export const Wrapper = styled.div`
  position: relative;
`;

export const Badge = styled.div`
  background-color: ${theme.colors.lightGreen};
  color: ${theme.colors.black};
  font-size: var(--step--2);
  line-height: 1;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 0 var(--space-3xs);
  border-radius: var(--space-2xs);
  position: absolute;
  top: var(--space-3xs);
  right: -8px;
  min-width: var(--space-s);
  height: var(--space-s);
  text-align: center;
`;
