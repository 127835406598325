import { groq } from 'next-sanity';

export const productPageQuery = groq`
  *[_type == "productVariant" && sku == $sku][0] {
    ...,
    sku,
    "slug": slug.current,
    categories[]->{
      "slug": slug.current,
    },
    product->{
      whyWeLoveIt,
      "nutshells": nutshells[]{
        ...,
        "icon": *[
          _type == "nutshell"
          && type == ^.type
        ][0]{
          name,
          icon->{
            title,
            icon {
              asset->
            }
          }
        }
      },
      howToPlant,
      variants[]->{
        sku,
        "slug": slug.current,
        attributes,
        categories[]->{
          "slug": slug.current,
        },
      }
    },
    suggestedPots[]->{
      _type,
      _id,
      name,
      photos,
      sku,
      attributes,
      "slug": slug.current,
    }
  }
`;
