import styled from 'styled-components';

import theme from 'utils/theme';

interface TitleHeadingProps {
  inline?: boolean;
}

const TitleHeading = styled.h1<TitleHeadingProps>`
  margin-top: 0;
  margin-bottom: ${({ inline }) => (inline ? 0 : '20px')};
  font-size: var(--step-10);
  line-height: 1.1;
  letter-spacing: -0.2px;
  color: inherit;
  font-family: ${theme.fonts.heading};

  p + & {
    margin-top: 1em;
  }
`;

export default TitleHeading;
