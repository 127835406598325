export const LayersQuery = `
  "layers": {
    "mobile": layers.mobile[]{
      ...,
      "asset": asset->
    },
    "desktop": layers.desktop[]{
      ...,
      "asset": asset->
    },
  }
`;
