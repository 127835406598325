import { groq } from 'next-sanity';

import { FeatureMetadataQuery } from './fragments';

export const partnersListingPageQuery = groq`
  *[_type == "partnerIndex"][0] {
    partners[]->{
      slug,
      name,
      shortLocation,
      banner {
          ...,
          image {
              asset->
          }
      }
    }
  }
`;

export const partnerPageQuery = groq`
{
  ${FeatureMetadataQuery},
  "partner": *[_type == "partner" && slug.current == $slug][0] {
    ...,
    "favPicks": favPicks[]->{
      name,
      sku,
      "slug": slug.current,
      "photo": photos[0],
      categories[]->{
        "slug": slug.current,
      },
    }
  }
}
`;
