import { groq } from 'next-sanity';

import { FeatureMetadataQuery } from './fragments';

export const categoryPageQuery = groq`
  {
    ${FeatureMetadataQuery},
    "categoryDetails": *[_type == "category" && $category == slug.current][0] {
      "title": name,
      "subTitle": subTitle,
      "description": description,
      "image": image,
      "darkenOverlay": darkenOverlay,
      "colorScheme": colorScheme,
      "video": video,
      "defaultSortingOption": defaultSortingOption,
    }
  }
`;
