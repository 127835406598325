import { groq } from 'next-sanity';

import { ProductsSkusQuery } from './fragments';

export const aboutPageQuery = groq`
  *[_type == "aboutPage" && slug.current == $slug][0] {
    ...,
    blocks[] {
      ...,
      "tabs": tabs[]{
        ...,
        ${ProductsSkusQuery},
      }
    }
  }
`;
