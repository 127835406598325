export const ProductsSkusQuery = `
  "products": products[]->{
    sku
  }
`;

export const ProductsMetaQuery = `
  "products": products[]->{
    name,
    sku,
    "slug": slug.current,
    "photo": photos[0],
    categories[]->{
      "slug": slug.current,
    },
  }
`;
