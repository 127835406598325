import { groq } from 'next-sanity';

import {
  ArticlesMetaQuery,
  LabelsQuery,
  LayersQuery,
  ProductsMetaQuery,
  ShortcutsQuery,
} from './fragments';

export const subscribePageQuery = groq`
  *[_type == "emailSubscribePage" && _id == "emailSubscribePage"][0] {
    blocks[] {
      ...,
      "tabs": tabs[]{
        ...,
        ${ProductsMetaQuery},
      },
      ${ShortcutsQuery},
      ${LayersQuery},
      ${ArticlesMetaQuery},
      ${LabelsQuery}
    }
  }
`;
