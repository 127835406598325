import styled from 'styled-components';

import theme from 'utils/theme';

interface Heading1Props {
  inline?: boolean;
}

const Heading1 = styled.h1<Heading1Props>`
  margin-top: 0;
  margin-bottom: ${({ inline }) => (inline ? 0 : '20px')};
  font-size: var(--step-9);
  line-height: 1.1;
  letter-spacing: -0.2px;
  color: inherit;
  font-family: ${theme.fonts.heading};

  p + & {
    margin-top: 1em;
  }
`;

export default Heading1;
