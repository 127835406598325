import { groq } from 'next-sanity';

import {
  ArticlesMetaQuery,
  LabelsQuery,
  ProductsMetaQuery,
  ShortcutsQuery,
} from './fragments';

export const carePageQuery = groq`
*[_type == "care" && slug.current == $slug][0] {
  name,
  blocks[] {
    ...,
    "tabs": tabs[]{
      ...,
      ${ProductsMetaQuery},
    },
    ${ShortcutsQuery},
    ${ArticlesMetaQuery},
    ${LabelsQuery}
  }
}
`;
