import { groq } from 'next-sanity';

export const siteGlobalsQuery = groq`
  {
    "siteGlobals": *[_type == "siteSettings" && _id == "siteSettings"][0] {
      ...,
      pressBanner {
        images[] {
          ...,
          asset->
        }
      },
    },
    "settings": {
      "partners": *[_type == "partnerSettings" && _id == "partnerSettings"][0] {
        ...,
        giftMessaging {
          ...,
          permissions[] {
            "hasGiftMessaging": hasGiftMessaging,
            "name": partnerRef->name,
            "slug": partnerRef->slug.current
          }
        }
      },
      "zendesk": *[_type == "zendeskSettings" && _id == "zendeskSettings"][0],
    }
  }
`;
