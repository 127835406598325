import styled, { css } from 'styled-components';

import theme from 'utils/theme';

interface ContainerProps {
  noGutter?: boolean;
  noPadding?: boolean;
  inset?: boolean;
  outset?: boolean;
}

const Container = styled.div<ContainerProps>`
  position: relative;
  max-width: 100%;
  margin: 0 auto;

  width: ${({ inset, outset, noGutter }) =>
    inset
      ? theme.pageWidth.inset
      : outset || noGutter
      ? theme.pageWidth.extended
      : theme.pageWidth.standard}px;

  ${(props) =>
    !props.noGutter &&
    css`
      padding: ${props.noPadding ? 0 : '0 var(--site-gutter)'};
    `};
`;

export default Container;
