import { groq } from 'next-sanity';

import { LayersQuery, ProductsSkusQuery } from './fragments';

export const editsListingPageQuery = groq`
  *[_type == "editIndex"][0] {
    title,
    body,
    showSubtitle,
    edits[]->{
      slug,
      name,
      subTitle,
      banner,
      darkenOverlay
    }
  }
`;

export const editsPageQuery = groq`
  *[_type == "edit" && slug.current == $slug][0] {
    ...,
    sets[] {
      ...,
      tabs[] {
        ...,
        ${ProductsSkusQuery},
      },
      "products": products[]->{
        sku,
        whyWeLoveIt,
        "nutshells": nutshells[]{
        ...,
        "icon": *[
          _type == "nutshell"
          && type == ^.type
        ][0]{
          name,
          icon->{
            title,
            icon {
              asset->
            }
          }
        }
      },
      },
      ${LayersQuery},
    }
  }
`;
