import styled from 'styled-components';

import theme from 'utils/theme';

export interface SectionProps extends React.ComponentPropsWithRef<'section'> {
  bg?: 'light' | 'dark' | 'normal' | 'irisPurple' | null;
  padding?: 'none' | 'small' | 'medium';
  fullBleed?: boolean;
}

const bgColors = {
  irisPurple: theme.colors.irisPurple,
  light: theme.colors.white,
  normal: theme.colors.offWhite,
  dark: theme.colors.darkBg,
};

const paddingSizes = {
  none: 0,
  small: 'var(--space-m-2xl) 0',
  medium: 'var(--space-2xl-3xl) 0',
};

const BaseSection = styled.section<SectionProps>`
  background-color: ${({ bg }) => (bg ? bgColors[bg] : undefined)};
  padding: ${({ padding = 'medium' }) =>
    padding ? paddingSizes[padding] : '0'};
`;

const FullBleedSection = styled(BaseSection)`
  & + & {
    margin-top: var(--space-s-l);
  }
`;

const LightSection = styled(BaseSection)`
  & + & {
    padding-top: 0;
  }
`;

const DefaultSection = styled(BaseSection)`
  & + & {
    padding-top: 0;
  }
`;

const Section = (props: SectionProps) => {
  let Comp = DefaultSection;

  if (props.fullBleed) {
    Comp = FullBleedSection;
  } else if (props.bg === 'light') {
    Comp = LightSection;
  }

  return <Comp {...props} />;
};

export default Section;
