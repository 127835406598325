export const ArticlesMetaQuery = `
  "articles": articles[]->{
    _createdAt,
    title,
    lede,
    slug,
    image,
    author->,
    category->
  }
`;
