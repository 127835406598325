import styled from 'styled-components';

import { Heading2 } from 'components/common/text';
import TitleLink from 'components/common/text/heading-link';
import { tablet } from 'utils/media';
import theme from 'utils/theme';

interface IHeadingWrapper {
  noGutter?: boolean;
}

const HeadingWrapper = styled.div<IHeadingWrapper>`
  display: flex;
  align-items: baseline;
  color: ${theme.colors.purple};
  flex-wrap: wrap;
  margin-bottom: var(--site-gutter);
  padding: 0 ${({ noGutter }) => (noGutter ? 0 : 'var(--site-gutter)')};

  ${Heading2} {
    font-size: var(--step-6);
  }

  ${tablet} {
    ${TitleLink} {
      margin-left: var(--space-xl);
    }
  }
`;

export default HeadingWrapper;
