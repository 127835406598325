import { groq } from 'next-sanity';

import { ArticlesMetaQuery, ProductsSkusQuery } from './fragments';

export const articlePageQuery = groq`
  *[_type == "article" && slug.current == $slug][0] {
    ...,
    headerBlocks[] {
      ...,
      ${ArticlesMetaQuery},
      "tabs": tabs[]{
        ...,
        ${ProductsSkusQuery},
      }
    },
    footerBlocks[] {
      ...,
      ${ArticlesMetaQuery},
      "tabs": tabs[]{
        ...,
        ${ProductsSkusQuery},
      }
    }
  }
`;
