import { groq } from 'next-sanity';

import {
  ArticlesMetaQuery,
  LabelsQuery,
  LayersQuery,
  ProductsMetaQuery,
  ShortcutsQuery,
} from './fragments';

export const homePageQuery = groq`
  *[_type == "homepage" && _id == "homepage"][0] {
    blocks[] {
      ...,
      "tabs": tabs[]{
        ...,
        ${ProductsMetaQuery},
      },
      ${ShortcutsQuery},
      ${LayersQuery},
      ${ArticlesMetaQuery},
      ${LabelsQuery}
    }
  }
`;
