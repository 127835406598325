const colors: Record<string, string> = {
  white: '#fff',
  offWhite: '#f9f6f3',
  darkBg: '#F2ECE7',
  black: '#000',
  offBlack: '#1A1A1A',
  lightestGrey: '#F7F7F7',
  lighterGrey: '#E9E8E7',
  lightGrey: '#E0DDDB',
  midGrey: '#B3B3B3',
  grey: '#727272',
  darkBlue: '#00366B',
  lightGreen: '#A3FF64',
  darkGreen: '#004A29',
  darkGreenBorder: 'rgba(0, 73, 40, 0.1)',
  gold: '#FFDC40',
  purple: '#A374FF',
  pink: '#FFC7FF',
  label: 'rgba(0, 0, 0, 0.5)',
  scrollbarBackground: 'rgba(0, 74, 41, 0.2)',
  iconUnderlay: 'rgba(0, 0, 0, 0.15)',
  modalBackground: 'rgba(0, 0, 0, 0.5)',
  modalBackgroundLight: 'rgba(0, 0, 0, 0.1)',
  loadingOverlay: 'rgba(167, 122, 255, 0.9)',
  errorRed: '#E02900',
  coleusGreen: '#a3ff64',
  irisPurple: '#A374FF',
  dahliaPink: '#ffc7ff',
  hydrangeaBlue: '#03dbff',
  pineGreen: '#004a29',
  pineGreenFaded: 'rgba(0, 74, 41, 0.3)',
  daffodilYellow: '#ffdc40',
  poppyRed: '#ff552e',
  thistleBlue: '#00366b',
  beige: '#f2ece7',
  blueChalk: '#ede4ff',
};

const theme = {
  fonts: {
    heading: `'Antique Olive', system-ui, -apple-system, 'Segoe UI', Roboto, Helvetica, Arial, sans-serif, 'Apple Color Emoji', 'Segoe UI Emoji'`,
    body: `Patron, system-ui, -apple-system, 'Segoe UI', Roboto, Helvetica, Arial, sans-serif, 'Apple Color Emoji', 'Segoe UI Emoji'`,
  },
  colors,
  weights: {
    normal: 400,
    bold: 700,
    headingBold: 900,
  },
  transitionSpeeds: {
    fastest: 0.1,
    fast: 0.2,
    normal: 0.3,
    slow: 0.4,
  },
  pageWidth: {
    inset: 1140,
    standard: 1440,
    extended: 1920,
  },
  zIndexes: {
    loader: 50,
    modals: 40,
    header: 30,
    high: 20,
    mid: 10,
    low: 1, // fixes
    normal: 0,
  },
  boxShadows: {
    light: '0px var(--space-3xs) var(--space-2xs) rgba(0, 0, 0, 0.1);',
  },
  sizes: {
    input: {
      height: 48,
    },
    button: {
      height: 48,
    },
    fonts: {
      input: 16,
    },
    cookies: {
      bar: 150,
      barTight: 120,
    },
  },
};

export default theme;
